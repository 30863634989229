import HttpHelper from '../../helpers/httpHelper';
require('dotenv').config();
const headerInfo = {};

class TransactionAPI {
    static async CreateBuyTransaction(req) {
        const url = process.env.REACT_APP_API_URL + '/swaps?code=' + req.code;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req.data);
    }

    static async CreateSellTransaction(req) {
        const url = process.env.REACT_APP_API_URL + '/swaps?code=' + req.code;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req.data);
    }

    static async CreateWithdrawTransaction(req) {
        const url = process.env.REACT_APP_API_URL + '/withdraws?code=' + req.code;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req.data);
    }

    static async GetBuyTransactions(status) {
        const url =
            process.env.REACT_APP_API_URL + '/swaps?type=Buy&status=' + status;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetSellTransactions(status) {
        const url =
            process.env.REACT_APP_API_URL + '/swaps?type=Sell&status=' + status;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdatePlanMember(req) {
        const url =
            process.env.REACT_APP_API_URL +
            `/planmeber/update/${req.id}?amount=${req.amount}`;
        return HttpHelper.httpRequest(url, 'PUT', headerInfo);
    }

    static async GetWithdrawTransactions(status) {
        const url = process.env.REACT_APP_API_URL + '/withdraws?status=' + status;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetPayouts(query) {
        const url =
            process.env.REACT_APP_API_URL +
            `/payouts?page=${query.page}&pageSize=${query.pageSize}&search=${
                query.search || ''
            }`;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetAllPayouts() {
        const url = process.env.REACT_APP_API_URL + '/allpayouts';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetPlanMembers(query) {
        const url =
            process.env.REACT_APP_API_URL +
            `/member/plans?page=${query.page}&pageSize=${
                query.pageSize
            }&search=${query.search || ''}`;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetContracts() {
        const url = process.env.REACT_APP_API_URL + '/contracts';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }
}

export default TransactionAPI;
