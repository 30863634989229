import HttpHelper from '../../helpers/httpHelper';
require('dotenv').config();
const headerInfo = {};

class PlanAPI {
    static async GetPlans() {
        const url = process.env.REACT_APP_API_URL + '/plans';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetPlanDetail(req) {
        const url =
            process.env.REACT_APP_API_COMMON_URL +
            `/plans/illustration/${req.id}?amount=${req.amount}&startDate=${req.date}`;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async CreatePlanMember(req) {
        const url = process.env.REACT_APP_API_URL + '/member/plans';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req);
    }

    static async UpdatePlan(req) {
        const url = process.env.REACT_APP_API_URL + '/plans/' + req.id;
        delete req.id;
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, req);
    }

    static async GetPlanMember(id) {
        const url = process.env.REACT_APP_API_URL + '/member/plans/' + id;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }
}

export default PlanAPI;
