import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { AuthenticationReducer } from './authentication/auth.reducer';
import { MemberReducer } from './member/member.reducers';
import { PlanReducer } from './plan/plan.reducers';
import { TransactionReducer } from './transaction/transaction.reducers';
import { UserActionTypes } from './user/user.model';
import { UserReducer } from './user/user.reducers';

const appReducer = combineReducers({
    UserReducer,
    AuthenticationReducer,
    TransactionReducer,
    PlanReducer,
    MemberReducer,
    UserReducer
});

const persistConfig = {
    key: 'mlm',
    storage: storageSession
};
const rootReducer = (state, action) => {
    if (action.type === UserActionTypes.USER_LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
