import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake.min';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import _ from 'underscore';
import { decodeToken, IsNullOrEmpty } from './utility';
require('dotenv').config();

export function generatePdf(
    data,
    title,
    text,
    pageSize = 'A3',
    pageOrientation = 'landscape'
) {
    const currentUser = decodeToken();
    var docDefinition = {
        pageSize: pageSize,
        pageOrientation: pageOrientation,
        pageMargins: [40, 60, 40, 60],
        footer: function (currentPage, pageCount) {
            return [
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 40,
                            y1: 10,
                            x2: 1140,
                            y2: 10,
                            lineWidth: 0.5
                        }
                    ]
                },
                {
                    text:
                        'Report Generated at ' +
                        moment().format('lll') +
                        ' by ' +
                        currentUser.email,
                    style: 'footerText',
                    margin: [40, 10, 20, 0]
                },
                {
                    text: currentPage.toString() + ' of ' + pageCount,
                    style: 'footer',
                    margin: [20, 10, 20, 0]
                }
            ];
        },
        header: function (currentPage, pageCount, pageSize) {
            return [
                { text: title, style: 'header', margin: [0, 20, 0, 0] },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 40,
                            y1: 0,
                            x2: 1140,
                            y2: 0,
                            lineWidth: 2
                        }
                    ]
                }
            ];
        },
        watermark: {
            text: 'Coin Shoppy Confidential',
            opacity: 0.3,
            bold: true,
            italics: false,
            fontSize: 50
        },
        content: [
            text,
            {
                style: 'table',
                heights: 40,
                padding: [10, 20, 10, 20],
                table: {
                    body: data
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? 2
                            : 1;
                    },
                    hLineColor: function (i, node) {
                        return i === 0 || i === node.table.body.length
                            ? 'black'
                            : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? 'black'
                            : 'gray';
                    },
                    paddingLeft: function (i, node) {
                        return 6;
                    },
                    paddingRight: function (i, node) {
                        return 6;
                    },
                    paddingTop: function (i, node) {
                        return 10;
                    },
                    paddingBottom: function (i, node) {
                        return 10;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex === 0
                            ? '#CECECE'
                            : rowIndex % 2 === 0
                            ? '#F4F4F4'
                            : '#FFFFFF';
                    }
                }
            }
        ],
        styles: {
            header: {
                fontSize: 25,
                bold: true,
                alignment: 'center',
                lineHeight: 1.2
            },
            footerText: {
                alignment: 'left',
                italics: true,
                bold: true,
                lineHeight: 1
            },
            footer: {
                fontSize: 12,
                italics: true,
                alignment: 'right',
                lineHeight: 1
            },
            table: {
                margin: [0, 10, 0, 0]
            }
        }
    };

    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    pdfMake
        .createPdf(docDefinition)
        .download(title + '_' + moment().unix().toString());
}

export function downloadIllustrationReport(items) {
    const data = [];
    data.push([
        'S.No',
        'Date',
        'Deposit Amount',
        'Reward Gained',
        'Net Withdraw',
        'Gas Fees',
        'Liquidity',
        'Reinvestment'
    ]);
    let cnt = 0;
    items.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.date },
            { text: item.amount },
            { text: item.interest },
            { text: item.withdrawalAmount },
            { text: item.adminFees },
            { text: item.liquidityFees },
            { text: item.reinvest }
        ]);
    });

    generatePdf(data, 'Plan Illustration', '', 'A4', 'portrait');
}

export function downloadReferralReport(referals) {
    referals = _.sortBy(referals, 'depth');
    const data = [];
    data.push([
        'S.No',
        'Level',
        'Member ID',
        'Member Name',
        'Email',
        'Mobile Number',
        'Referred By'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: parseInt(item.depth) + 1 },
            { text: item.memberId },
            { text: item.firstName + ' ' + item.lastName },
            {
                text: item.email.replace(
                    /^(.)(.*)(.@.*)$/,
                    (_, a, b, c) => a + b.replace(/./g, '*') + c
                )
            },
            {
                text:
                    item.mobileNumber.replace(/\D/g, '').substr(0, 5) + 'XXXXX',
                noWrap: true
            },
            { text: item.referal }
        ]);
    });

    generatePdf(data, 'Referral Report', '', 'A3', 'Portrait');
}

export function downloadSwapReport(deposits, type) {
    const data = [];
    data.push([
        'S.No',
        'Transaction Date',
        'Reference No',
        'Transaction ID',
        'Coins',
        'USD',
        'Fees',
        'Spent Hash',
        'Buy Hash',
        'Fees Hash'
    ]);
    let cnt = 0;

    deposits.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: moment(item.createdAt).format('lll'), noWrap: true },
            { text: item.referenceNo },
            { text: item.transactionId },
            { text: item.transactionCoins + ' ' + item.coinType, noWrap: true },
            {
                text: item.transactionTokens + ' ' + item.tokenSymbol,
                noWrap: true
            },
            {
                text: item.transactionCharge + ' ' + item.coinType,
                noWrap: true
            },
            {
                text: !IsNullOrEmpty(item.spentHash) ? 'Hash' : '',
                link: 'https://bscscan.com/tx/' + item.spentHash,
                decoration: 'underline'
            },
            {
                text: !IsNullOrEmpty(item.receiveHash) ? 'Hash' : '',
                link: 'https://bscscan.com/tx/' + item.receiveHash,
                decoration: 'underline'
            },
            {
                text: !IsNullOrEmpty(item.transactionChargeHash) ? 'Hash' : '',
                link: 'https://bscscan.com/tx/' + item.transactionChargeHash,
                decoration: 'underline'
            }
        ]);
    });

    generatePdf(data, type, '');
}

export function downloadWithdrawReport(withdraws) {
    const data = [];
    data.push([
        'S.No',
        'Transaction Date',
        'Reference No',
        'Transaction ID',
        'Amount'
    ]);
    let cnt = 0;

    withdraws.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: moment(item.createdAt).format('lll'), noWrap: true },
            { text: item.referenceNo },
            { text: item.transactionId },
            {
                text:
                    item.amount +
                    ' ' +
                    (item.isToken ? item.tokenSymbol : item.coinType),
                noWrap: true
            }
        ]);
    });

    generatePdf(data, 'Withdraw Report', '', 'A3', 'Portrait');
}

export function downloadRewardReport(referals) {
    referals = referals.filter((t) => t.method === 'Reward');
    referals = _.sortBy(referals, 'memberId');
    const data = [];
    data.push([
        'S.No',
        'Type',
        'Transaction Time',
        'From',
        'To',
        'Name',
        'Email',
        'Mobile Number',
        'Reward',
        'Status'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.memberId },
            {
                text: item.members[0].firstName + ' ' + item.members[0].lastName
            },
            { text: item.members[0].email },
            { text: item.members[0].mobileNumber },
            { text: item.amount },
            { text: item.status }
        ]);
    });

    generatePdf(data, 'Reward Report', '', 'A3');
}

export function downloadRefReport(referals) {
    referals = referals.filter((t) => t.method === 'Referral');
    referals = _.sortBy(referals, 'memberId');
    const data = [];
    data.push([
        'S.No',
        'Type',
        'Transaction Time',
        'From',
        'To',
        'Name',
        'Email',
        'Mobile Number',
        'Reward',
        'Status'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.memberId },
            {
                text: item.members[0].firstName + ' ' + item.members[0].lastName
            },
            { text: item.members[0].email },
            { text: item.members[0].mobileNumber },
            { text: item.amount },
            { text: item.status }
        ]);
    });

    generatePdf(data, 'Referral Report', '', 'A3');
}

export function downloadReTopReport(referals) {
    referals = _.sortBy(referals, 'memberId');
    const data = [];
    data.push([
        'S.No',
        'Type',
        'Transaction Time',
        'From',
        'To',
        'Name',
        'Email',
        'Mobile Number',
        'Reward',
        'Status'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.memberId },
            {
                text: item.members[0].firstName + ' ' + item.members[0].lastName
            },
            { text: item.members[0].email },
            { text: item.members[0].mobileNumber },
            { text: item.reinvestment },
            { text: item.status }
        ]);
    });

    generatePdf(data, 'Retopup Report', '', 'A3');
}

export function downloadReTopCommissionReport(withdraws) {
    const data = [];
    data.push([
        'S.No',
        'Method',
        'Created Date',
        'Transaction Date',
        'Coins',
        'Type',
        'Status'
    ]);
    let cnt = 0;

    withdraws.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.method },
            {
                text:
                    (item.planMembers &&
                        item.planMembers[0] &&
                        moment(item.planMembers[0].createdAt).format('lll')) ||
                    moment(item.createdAt).format('lll'),
                noWrap: true
            },
            { text: moment(item.createdAt).format('lll') },
            { text: '$ ' + item.reinvestment },
            { text: item.status },
            { text: item.type }
        ]);
    });

    generatePdf(data, 'Retopup Report', '', 'A3', 'Portrait');
}

export function downloadActiveIDReport(referals) {
    referals = _.filter(referals, (s) => s.planMember.length > 0);
    const data = [];
    data.push([
        'S.No',
        'Member ID',
        'Member Name',
        'Email',
        'Mobile Number',
        'Referred By',
        'Joined On',
        'Activated On'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        const value = item.planMember;
        let active = '';
        if (value.length === 0) {
            active = 'Not Activated';
        } else {
            if (value.length > 1) {
                let latestDate = _.sortBy(value, (t) =>
                    moment(t.createdAt).unix()
                );
                latestDate = latestDate[0].createdAt;
                active = moment(latestDate).format('LLL');
            } else {
                const latestDate = value[0].createdAt;
                active = moment(latestDate).format('LLL');
            }
        }

        data.push([
            { text: cnt },
            { text: item.memberId },
            { text: item.firstName + ' ' + item.lastName },
            {
                text: item.email
            },
            { text: item.mobileNumber },
            { text: item.referal },
            { text: moment(item.createdAt).format('LLL') },
            { text: active }
        ]);
    });

    generatePdf(data, 'Active ID Report', '', 'A3');
}

export function downloadInActiveIDReport(referals) {
    referals = _.filter(referals, (s) => s.planMember.length === 0);
    const data = [];
    data.push([
        'S.No',
        'Member ID',
        'Member Name',
        'Email',
        'Mobile Number',
        'Referred By',
        'Joined On',
        'Email Verified',
        'Mobile Verified',
        'Wallet Created'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        const value = item.planMember;
        let active = '';
        if (value.length === 0) {
            active = 'Not Activated';
        } else {
            if (value.length > 1) {
                let latestDate = _.sortBy(value, (t) =>
                    moment(t.createdAt).unix()
                );
                latestDate = latestDate[0].createdAt;
                active = moment(latestDate).format('LLL');
            } else {
                const latestDate = value[0].createdAt;
                active = moment(latestDate).format('LLL');
            }
        }

        data.push([
            { text: cnt },
            { text: item.memberId },
            { text: item.firstName + ' ' + item.lastName },
            {
                text: item.email
            },
            { text: item.mobileNumber },
            { text: item.referal },
            { text: moment(item.createdAt).format('LLL') },
            { text: item.isEmailVerified ? 'Yes' : 'No' },
            { text: item.isMobileVerified ? 'Yes' : 'No' },
            { text: item.walletAddress ? 'Yes' : 'No' }
        ]);
    });

    generatePdf(data, 'In Active ID Report', '', 'A3');
}

export function downloadPayoutReport(referals) {
    referals = _.sortBy(referals, 'memberId');
    const data = [];
    data.push([
        'S.No',
        'Type',
        'Transaction Time',
        'From',
        'To',
        'Name',
        'Email',
        'Mobile Number',
        'Reward',
        'Status'
    ]);
    let cnt = 0;

    referals.forEach((item, index) => {
        cnt = cnt + 1;
        data.push([
            { text: cnt },
            { text: item.type },
            { text: moment(item.createdAt).format('lll') },
            {
                text: item.createdBy
            },
            { text: item.memberId },
            {
                text: item.members[0].firstName + ' ' + item.members[0].lastName
            },
            { text: item.members[0].email },
            { text: item.members[0].mobileNumber },
            { text: item.amount },
            { text: item.status }
        ]);
    });

    generatePdf(data, 'Payout Report', '', 'A3');
}
