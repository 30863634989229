import { call, put, takeEvery } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import * as TransactionActions from './transaction.actions';
import TransactionAPI from './transaction.api';
import { TransactionActionTypes } from './transaction.model';

export function* handleBuyCreate(action) {
    try {
        const response = yield call(
            TransactionAPI.CreateBuyTransaction,
            action.payload
        );
        yield put(TransactionActions.createBuySuccess(response));
        yield put(TransactionActions.fetchBuyRequest('Approved'));
        Swal.fire({
            title: 'Buy Request',
            text: 'Tokens bought successfully',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showConfirmButton: false,
            cancelButtonText: 'Close',
            customClass: {
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3'
            }
        });
    } catch (e) {
        yield put(TransactionActions.createBuyFailure(e));
    }
}

export function* handleSellCreate(action) {
    try {
        const response = yield call(
            TransactionAPI.CreateSellTransaction,
            action.payload
        );
        yield put(TransactionActions.createSellSuccess(response));
        yield put(TransactionActions.fetchSellRequest('Approved'));
        Swal.fire({
            title: 'Sell Request',
            text: 'Tokens Sold successfully',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showConfirmButton: false,
            cancelButtonText: 'Close',
            customClass: {
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3'
            }
        });
    } catch (e) {
        yield put(TransactionActions.createSellFailure(e));
    }
}

export function* handleWithdrawCreate(action) {
    try {
        const response = yield call(
            TransactionAPI.CreateWithdrawTransaction,
            action.payload
        );
        yield put(TransactionActions.createWithdrawSuccess(response));
        yield put(TransactionActions.fetchWithdrawRequest('Approved'));
        Swal.fire({
            title: 'Withdraw Request',
            text: 'Coins withdrawn successfully',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showConfirmButton: false,
            cancelButtonText: 'Close',
            customClass: {
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3'
            }
        });
    } catch (e) {
        yield put(TransactionActions.fetchWithdrawFailure(e));
    }
}

export function* handleBuyTransactions(action) {
    try {
        const response = yield call(
            TransactionAPI.GetBuyTransactions,
            action.payload
        );
        yield put(TransactionActions.fetchBuySuccess(response));
    } catch (e) {
        yield put(TransactionActions.fetchBuyFailure(e));
    }
}

export function* handleSellTransactions(action) {
    try {
        const response = yield call(
            TransactionAPI.GetSellTransactions,
            action.payload
        );
        yield put(TransactionActions.fetchSellSuccess(response));
    } catch (e) {
        yield put(TransactionActions.fetchSellFailure(e));
    }
}

export function* handleWithdrawTransactions(action) {
    try {
        const response = yield call(
            TransactionAPI.GetWithdrawTransactions,
            action.payload
        );
        yield put(TransactionActions.fetchWithdrawSuccess(response));
    } catch (e) {
        yield put(TransactionActions.fetchWithdrawFailure(e));
    }
}

export function* handlePayouts(action) {
    try {
        const response = yield call(TransactionAPI.GetPayouts, action.payload);
        yield put(TransactionActions.fetchPayoutSuccess(response));
    } catch (e) {
        yield put(TransactionActions.fetchPayoutFailure(e));
    }
}

export function* handleContracts(action) {
    try {
        const response = yield call(
            TransactionAPI.GetContracts,
            action.payload
        );
        yield put(TransactionActions.fetchContractSuccess(response));
    } catch (e) {
        yield put(TransactionActions.fetchContractFailure(e));
    }
}

export function* handlePlanMembers(action) {
    try {
        const response = yield call(
            TransactionAPI.GetPlanMembers,
            action.payload
        );
        yield put(TransactionActions.fetchPlanMemberSuccess(response));
    } catch (e) {
        yield put(TransactionActions.fetchPlanMemberFailure(e));
    }
}

export function* handleUpdatePlanMembers(action) {
    try {
        const response = yield call(
            TransactionAPI.UpdatePlanMember,
            action.payload
        );
        yield put(TransactionActions.updatePlanMemberSuccess(response));
    } catch (e) {
        yield put(TransactionActions.updatePlanMemberFailure(e));
    }
}

export function* TransactionSaga() {
    yield takeEvery(TransactionActionTypes.CREATE_BUY_REQUEST, handleBuyCreate);
    yield takeEvery(
        TransactionActionTypes.CREATE_SELL_REQUEST,
        handleSellCreate
    );
    yield takeEvery(
        TransactionActionTypes.CREATE_WITHDRAW_REQUEST,
        handleWithdrawCreate
    );
    yield takeEvery(
        TransactionActionTypes.FETCH_BUY_REQUEST,
        handleBuyTransactions
    );
    yield takeEvery(
        TransactionActionTypes.FETCH_SELL_REQUEST,
        handleSellTransactions
    );
    yield takeEvery(
        TransactionActionTypes.FETCH_WITHDRAW_REQUEST,
        handleWithdrawTransactions
    );
    yield takeEvery(TransactionActionTypes.FETCH_PAYOUT_REQUEST, handlePayouts);
    yield takeEvery(
        TransactionActionTypes.FETCH_CONTRACT_REQUEST,
        handleContracts
    );
    yield takeEvery(
        TransactionActionTypes.FETCH_PLANMEMBER_REQUEST,
        handlePlanMembers
    );
    yield takeEvery(
        TransactionActionTypes.UPDATE_PLANMEMBER_REQUEST,
        handleUpdatePlanMembers
    );
}
