import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/toastr.min.css';
import ProtectedRoute from './helpers/PrivateRoute';
import BuyContainer from './pages/buy/Buy.Container';
import ContractContainer from './pages/contract/Contract.Container';
import DashboardContainer from './pages/dashboard/Dashboard.Container';
import LoginContainer from './pages/login/Login.Container';
import MemberContainer from './pages/member/Member.Container';
import MemberTransContainer from './pages/memberTransactions/MemberTrans.Container';
import PayoutContainer from './pages/payout/Payout.Container';
import PlanMemberContainer from './pages/planMember/PlanMember.Container';
import PlanContainer from './pages/plans/Plan.Container';
import ProfileContainer from './pages/profile/Profile.Container';
import ResetPassword from './pages/profile/reset';
import ReportContainer from './pages/reports/Report.Container';
import SellContainer from './pages/sell/Sell.Container';
import UserContainer from './pages/user/User.Container';
import OTPVerify from './pages/verify/otp-verify';
import WithdrawContainer from './pages/withdraw/Withdraw.Container';
import theme from './theme';

export default function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/login" element={<LoginContainer />} />
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <DashboardContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/profile"
                        element={
                            <ProtectedRoute>
                                <ProfileContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route path="/reset" element={<ResetPassword />}></Route>
                    <Route
                        path="/verify"
                        element={
                            <ProtectedRoute>
                                <OTPVerify />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/plans"
                        element={
                            <ProtectedRoute>
                                <PlanContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/payouts"
                        element={
                            <ProtectedRoute>
                                <PayoutContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/planMembers"
                        element={
                            <ProtectedRoute>
                                <PlanMemberContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/contracts"
                        element={
                            <ProtectedRoute>
                                <ContractContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/members"
                        element={
                            <ProtectedRoute>
                                <MemberContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/memberTransactions"
                        element={
                            <ProtectedRoute>
                                <MemberTransContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/users"
                        element={
                            <ProtectedRoute>
                                <UserContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/buy-transactions"
                        element={
                            <ProtectedRoute>
                                <BuyContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/sell-transactions"
                        element={
                            <ProtectedRoute>
                                <SellContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/withdraws"
                        element={
                            <ProtectedRoute>
                                <WithdrawContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                    <Route
                        path="/reports"
                        element={
                            <ProtectedRoute>
                                <ReportContainer />
                            </ProtectedRoute>
                        }
                    ></Route>
                </Routes>
            </BrowserRouter>
        </MuiThemeProvider>
    );
}
