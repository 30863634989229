import { UserActionTypes } from './user.model';
require('dotenv').config();

export const initialState = {
    user: {},
    users: [],
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.REGISTER_USER_REQUEST:
        case UserActionTypes.UPDATE_USER_REQUEST:
        case UserActionTypes.FETCH_USER_REQUEST:
        case UserActionTypes.FETCH_USERS_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case UserActionTypes.FETCH_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload,
                isLoading: false
            };
        }

        case UserActionTypes.REGISTER_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case UserActionTypes.FETCH_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };
        }

        case UserActionTypes.UPDATE_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                user: action.payload
            };
        }

        case UserActionTypes.REGISTER_USER_FAILURE:
        case UserActionTypes.FETCH_USERS_FAILURE:
        case UserActionTypes.UPDATE_USER_FAILURE:
        case UserActionTypes.FETCH_USER_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as UserReducer };
