import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import {
    AccountBoxSharp,
    AccountCircle,
    Email,
    Fingerprint,
    PersonAdd,
    PhoneAndroid
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserRequest } from '../../store/user/user.actions';
import { getCurrentUser } from '../../store/user/user.selector';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';
import ChangePassword from './changePassword';

export default function ProfileContainer() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [acknowledge, setAcknowledge] = useState(false);

    useEffect(() => {
        dispatch(fetchUserRequest());
    }, []);

    const { currentUser, isLoading } = useSelector((state) => {
        return {
            currentUser: getCurrentUser(state),
            isLoading: state.UserReducer.isLoading
        };
    }, []);

    useEffect(() => {
        setOpen(false);
        setAcknowledge(false);
    }, [currentUser]);

    return (
        <div>
            {isLoading && <Loader />}
            <TitleBar
                heading="Profile Information"
                secondary={'Profile Information'}
                icon={<AccountCircle fontSize="large" htmlColor="goldenrod" />}
            />
            <Grid container spacing={2} className="content-height">
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <AccountBoxSharp />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    currentUser.firstName +
                                    ' ' +
                                    currentUser.lastName
                                }
                                secondary="Name"
                            />
                        </ListItem>
                        <Divider />

                        <ListItem>
                            <ListItemAvatar>
                                <Fingerprint />
                            </ListItemAvatar>
                            <ListItemText
                                primary={currentUser.userId}
                                secondary="User #"
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <Email />
                            </ListItemAvatar>
                            <ListItemText
                                primary={currentUser.email}
                                secondary="Email"
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <PhoneAndroid />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    currentUser.country +
                                    ' ' +
                                    currentUser.mobileNumber
                                }
                                secondary="Mobile Number"
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemAvatar>
                                <PersonAdd />
                            </ListItemAvatar>
                            <ListItemText
                                primary={currentUser.role}
                                secondary="Role"
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <ChangePassword canShow={true} />
                </Grid>
            </Grid>
        </div>
    );
}
