import { Card, CardHeader, Grid, makeStyles } from '@material-ui/core';
import {
    AccountBalance,
    AccountTree,
    ArrowDownward,
    ArrowUpward,
    CloudDownload,
    DateRange,
    Event,
    Group,
    MonetizationOn,
    PersonAdd,
    PersonAddDisabled,
    RedoTwoTone,
    Report
} from '@material-ui/icons';
import React, { useState } from 'react';
import {
    downloadActiveIDReport,
    downloadInActiveIDReport,
    downloadPayoutReport,
    downloadReTopCommissionReport,
    downloadReTopReport,
    downloadRefReport,
    downloadRewardReport,
    downloadSwapReport,
    downloadWithdrawReport
} from '../../helpers/PDFGenerator';
import MemberAPI from '../../store/member/member.api';
import TransactionAPI from '../../store/transaction/transaction.api';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        cursor: 'pointer'
    }
}));

export default function ReportContainer() {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    return (
        <div className="mb-4 pb-4">
            <TitleBar
                heading="Reports"
                secondary={''}
                icon={<Report fontSize="large" htmlColor="goldenrod" />}
            />
            {loading && <Loader />}
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                MemberAPI.GetMembers()
                                    .then((t) => {
                                        downloadActiveIDReport(t);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<PersonAdd color="secondary" />}
                                title={'Active ID Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                MemberAPI.GetMembers()
                                    .then((t) => {
                                        downloadInActiveIDReport(t);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<PersonAddDisabled color="secondary" />}
                                title={'In-active ID Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetAllPayouts()
                                    .then((t) => {
                                        downloadPayoutReport(t);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<MonetizationOn color="secondary" />}
                                title={'Payout Report'}
                                subheader="All Users"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetWithdrawTransactions(
                                    'Approved'
                                )
                                    .then((s) => {
                                        downloadWithdrawReport(s);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<AccountBalance color="secondary" />}
                                title={'Withdraw Report'}
                                subheader="All Withdraw Transactions"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetAllPayouts()
                                    .then((s) => {
                                        downloadRewardReport(s);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<DateRange color="secondary" />}
                                title={'Rewards Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetAllPayouts()
                                    .then((s) => {
                                        downloadReTopReport(s);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<Event color="secondary" />}
                                title={'Retopup Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetAllPayouts()
                                    .then((s) => {
                                        downloadRefReport(s);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<AccountTree color="secondary" />}
                                title={'Referal commission'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetAllPayouts()
                                    .then((s) => {
                                        downloadReTopCommissionReport(s);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<RedoTwoTone color="secondary" />}
                                title={'Retopup commission'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={0}
                            onClick={() => {
                                console.log('Test');
                            }}
                        >
                            <CardHeader
                                avatar={<Group color="secondary" />}
                                title={'Team Retopup commission'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={0}
                            onClick={() => {}}
                        >
                            <CardHeader
                                avatar={<PersonAdd color="secondary" />}
                                title={'Referral Report'}
                                subheader="All Levels"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetBuyTransactions('Approved')
                                    .then((s) => {
                                        downloadSwapReport(
                                            s,
                                            'Buy Transaction Report'
                                        );
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<ArrowUpward color="secondary" />}
                                title={'Buy Report'}
                                subheader="All Buy Transactions"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="p-3">
                        <Card
                            className="cursor"
                            elevation={10}
                            onClick={() => {
                                setLoading(true);
                                TransactionAPI.GetSellTransactions('Approved')
                                    .then((s) => {
                                        downloadSwapReport(
                                            s,
                                            'Sell Transaction Report'
                                        );
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            <CardHeader
                                avatar={<ArrowDownward color="secondary" />}
                                title={'Sell Report'}
                                subheader="All Sell Transactions"
                                action={
                                    <CloudDownload
                                        className="mt-3"
                                        color="primary"
                                    />
                                }
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
