import HttpHelper from '../../helpers/httpHelper';
import {
    get2FASetup,
    getChangePasswordURI,
    getForgotPasswordURI,
    getSendEmailOTP,
    getSendPhoneOTP,
    getStatUrl,
    setSendEmailOTP,
    setSendPhoneOTP,
    verify2FAUrl
} from './user.uri';

require('dotenv').config();
const headerInfo = {};

class UserAPI {
    static async RegisterUser(req) {
        const url = process.env.REACT_APP_API_URL + '/user';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req);
    }

    static async GetUsers() {
        const url = process.env.REACT_APP_API_URL + '/users';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetCurrentUser() {
        const url = process.env.REACT_APP_API_URL + '/user';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdateCurrentUser(req) {
        const url = process.env.REACT_APP_API_URL + '/user';
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, req);
    }

    static async SetEmailOTP(req) {
        const url = getSendEmailOTP();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async SetPhoneOTP(req) {
        const url = getSendPhoneOTP();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async VerifyEmailOTP(req) {
        const url = setSendEmailOTP(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async VerifyPhoneOTP(req) {
        const url = setSendPhoneOTP(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Get2FASetUpCode(req) {
        const url = get2FASetup();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Verify2FACode(code) {
        const url = verify2FAUrl();
        return HttpHelper.httpRequest(
            url,
            'PATCH',
            {},
            {
                code: code
            }
        );
    }

    static async GetStats() {
        const url = getStatUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async ForgotPassword(req) {
        const url = getForgotPasswordURI(req);
        return HttpHelper.httpRequest(url, 'POST', {});
    }

    static async ChangePassword(req) {
        const url = getChangePasswordURI();
        return HttpHelper.httpRequest(url, 'PATCH', {}, req);
    }
}

export default UserAPI;
