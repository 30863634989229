import { Card, CardHeader, Grid } from '@material-ui/core';
import {
    AccountBalanceWallet,
    AccountBalanceWalletTwoTone,
    CheckCircle,
    EmailSharp,
    MobileFriendly,
    Person,
    PlaceRounded,
    VerticalAlignBottom,
    VerticalAlignTop
} from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useEffect, useState } from 'react';
import MemberAPI from '../../store/member/member.api';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';

export default function DashboardContainer() {
    const [isLoading, setLoading] = useState(false);
    const [stats, setStats] = useState({
        totalMembers: 0,
        mobileVerifiedMembers: 0,
        emailVerifiedMembers: 0,
        twoFactorAuthmembers: 0,
        walletWhitelistedMembers: 0,
        tokenApprovedMembers: 0,
        inPlanMembers: 0,
        totalInvestedTokens: 0,
        buyTransactionCount: 0,
        sellTransactionCount: 0,
        totalWithdrawCount: 0,
        totalTokensWithdraw: 0,
        totalCoinsWithdraw: 0,
        totalCoinsBought: 0,
        totalTokensBought: 0,
        totalTokensSold: 0,
        totalCoinsSold: 0
    });

    useEffect(() => {
        setLoading(true);
        MemberAPI.GetStats()
            .then((d) => {
                setStats(d);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <TitleBar
                heading="Dashboard"
                secondary={''}
                icon={
                    <HelpOutlineIcon fontSize="large" htmlColor="goldenrod" />
                }
            />
            {isLoading && <Loader />}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <Person
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.totalMembers}
                            subheader="Total Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <MobileFriendly
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.mobileVerifiedMembers}
                            subheader="Mobile Verified Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <EmailSharp
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.emailVerifiedMembers}
                            subheader="Email Verified Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <AccountBalanceWallet
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.twoFactorAuthmembers}
                            subheader="Wallet Created Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <AccountBalanceWalletTwoTone
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.walletWhitelistedMembers}
                            subheader="Whitelist Wallet Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <CheckCircle
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.tokenApprovedMembers}
                            subheader="Token Approved Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <PlaceRounded
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.inPlanMembers}
                            subheader="Total Plan Members"
                        />
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <VerticalAlignTop
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.buyTransactionCount}
                            subheader={'Buy Transactions'}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={
                                <VerticalAlignBottom
                                    color="secondary"
                                    fontSize="large"
                                    style={{ color: 'goldenrod' }}
                                />
                            }
                            title={stats.sellTransactionCount}
                            subheader={'Sell Transactions'}
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
