import { Button, Container, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Save } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { IsNullOrEmpty, validEmail, validPhone } from '../../helpers/utility';
import { updateMemberRequest } from '../../store/member/member.actions';
import MemberAPI from '../../store/member/member.api';

export default function UpdateMember(props) {
    const { selectedMember, onClose, members } = props;
    const dispatch = useDispatch();
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        country: '+91',
        mobileNumber: '',
        email: '',
        referedBy: '',
        referalName: ''
    });

    useEffect(() => {
        if (selectedMember && selectedMember._id) {
            setValues({
                ...values,
                firstName: selectedMember.firstName,
                lastName: selectedMember.lastName,
                country: selectedMember.country,
                mobileNumber: selectedMember.mobileNumber,
                email: selectedMember.email,
                referedBy: selectedMember.referal
            });
        }
    }, [selectedMember]);

    const [isValidated, setValidated] = React.useState(false);

    const handleReferral = () => {
        if (!IsNullOrEmpty(values.referedBy)) {
            MemberAPI.GetMemberDetail(values.referedBy).then((d) => {
                setValues({ ...values, referalName: d });
            });
        } else {
            setValues({ ...values, referalName: 'Company' });
        }
    };

    const validate = () => {
        if (IsNullOrEmpty(values.firstName)) {
            return false;
        }
        if (IsNullOrEmpty(values.lastName)) {
            return false;
        }
        if (
            IsNullOrEmpty(values.mobileNumber) ||
            !validPhone(values.mobileNumber)
        ) {
            return false;
        }
        if (IsNullOrEmpty(values.email) || !validEmail(values.email)) {
            return false;
        }

        if (!IsNullOrEmpty(values.referedBy)) {
            if (IsNullOrEmpty(values.referalName)) {
                Swal.fire({
                    title: 'Invalid Referal Code',
                    text: 'Please verify the proper referal code',
                    icon: 'error'
                });
                return false;
            }
        }
        return true;
    };

    // useEffect(() => {
    //     if (values.referedBy && values.referedBy.length > 5) {
    //         const isExist = _.find(members, s => s.memberId === values.referedBy);
    //         if (isExist) {
    //             setValues({ ...values, referalName: isExist.firstName + " " + isExist.lastName });
    //         }
    //     }
    // }, [values.referedBy])

    const handleSave = () => {
        if (validate()) {
            dispatch(
                updateMemberRequest({
                    id: selectedMember._id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    country: '+91',
                    mobileNumber: values.mobileNumber,
                    email: values.email,
                    referal: values.referedBy || 'ADM00001'
                })
            );
        } else {
            setValidated(true);
        }
    };

    return (
        <Container maxWidth="md" className="mt-4">
            <Grid container spacing={4}>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="First Name"
                        label="First Name"
                        value={values.firstName}
                        onChange={(e) =>
                            setValues({ ...values, firstName: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={isValidated && IsNullOrEmpty(values.firstName)}
                        helperText={
                            isValidated && IsNullOrEmpty(values.firstName)
                                ? 'First Name should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Last Name"
                        label="Last Name"
                        value={values.lastName}
                        onChange={(e) =>
                            setValues({ ...values, lastName: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={isValidated && IsNullOrEmpty(values.lastName)}
                        helperText={
                            isValidated && IsNullOrEmpty(values.lastName)
                                ? 'Last Name should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Mobile Number"
                        label="Mobile Number"
                        value={values.mobileNumber}
                        type="Mobile Number"
                        onChange={(e) =>
                            setValues({
                                ...values,
                                mobileNumber: e.target.value
                            })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.mobileNumber) ||
                                !validPhone(values.mobileNumber))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.mobileNumber) ||
                                !validPhone(values.mobileNumber))
                                ? 'Mobile Number should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Email"
                        label="Email"
                        value={values.email}
                        type="email"
                        onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.email) ||
                                !validEmail(values.email))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.email) ||
                                !validEmail(values.email))
                                ? 'Email should be valid'
                                : ''
                        }
                    />
                </Grid>

                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Refered By"
                        label="Refered By"
                        value={values.referedBy}
                        onChange={(e) =>
                            setValues({
                                ...values,
                                referedBy: e.target.value,
                                referalName: ''
                            })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        onBlur={() => handleReferral()}
                        helperText={
                            "Leave blank, if you don't have any referal now"
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Refered Name"
                        label="Refered Name"
                        value={values.referalName}
                        fullWidth
                        margin="dense"
                        className="px-2"
                        disabled
                    />
                </Grid>
                <Grid xs={12} sm={12} className="p-2">
                    <Container maxWidth="sm">
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6} className="p-2">
                                <Button
                                    startIcon={<Save />}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => handleSave()}
                                >
                                    Update Member
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6} className="p-2">
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    onClick={() => onClose()}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
