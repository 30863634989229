import { TransactionActionTypes } from './transaction.model';
require('dotenv').config();

export const initialState = {
    buyRequests: [],
    sellRequests: [],
    withdrawRequests: [],
    planMembers: [],
    payouts: [],
    contracts: [],
    totalPlanMembers: 0,
    totalPayouts: 0,
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.CREATE_BUY_REQUEST:
        case TransactionActionTypes.FETCH_PAYOUT_REQUEST:
        case TransactionActionTypes.FETCH_CONTRACT_REQUEST:
        case TransactionActionTypes.FETCH_PLANMEMBER_REQUEST:
        case TransactionActionTypes.CREATE_SELL_REQUEST:
        case TransactionActionTypes.CREATE_WITHDRAW_REQUEST:
        case TransactionActionTypes.FETCH_BUY_REQUEST:
        case TransactionActionTypes.FETCH_SELL_REQUEST:
        case TransactionActionTypes.FETCH_WITHDRAW_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case TransactionActionTypes.CREATE_BUY_SUCCESS:
        case TransactionActionTypes.CREATE_SELL_SUCCESS:
        case TransactionActionTypes.CREATE_WITHDRAW_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case TransactionActionTypes.FETCH_BUY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                buyRequests: action.payload
            };
        }

        case TransactionActionTypes.FETCH_SELL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                sellRequests: action.payload
            };
        }

        case TransactionActionTypes.FETCH_WITHDRAW_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                withdrawRequests: action.payload
            };
        }

        case TransactionActionTypes.FETCH_PAYOUT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                payouts: action.payload.payouts,
                totalPayouts: action.payload.totalPayouts
            };
        }

        case TransactionActionTypes.FETCH_CONTRACT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                contracts: action.payload
            };
        }

        case TransactionActionTypes.FETCH_PLANMEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planMembers: action.payload.planMembers,
                totalPlanMembers: action.payload.totalPlanMembers
            };
        }

        case TransactionActionTypes.UPDATE_PLANMEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planMembers: state.planMembers.map((t) => {
                    if (t._id === action.payload._id) {
                        debugger;
                        t = action.payload;
                    }
                    return t;
                })
            };
        }

        case TransactionActionTypes.CREATE_BUY_FAILURE:
        case TransactionActionTypes.CREATE_SELL_FAILURE:
        case TransactionActionTypes.FETCH_CONTRACT_FAILURE:
        case TransactionActionTypes.FETCH_PLANMEMBER_FAILURE:
        case TransactionActionTypes.FETCH_PAYOUT_FAILURE:
        case TransactionActionTypes.CREATE_WITHDRAW_FAILURE:
        case TransactionActionTypes.FETCH_BUY_FAILURE:
        case TransactionActionTypes.FETCH_SELL_FAILURE:
        case TransactionActionTypes.FETCH_WITHDRAW_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as TransactionReducer };
