import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from '@material-ui/core';
import {
    AccountBalanceWallet,
    AccountBalanceWalletRounded,
    CalendarToday,
    Close,
    Code,
    FileCopy,
    Fingerprint,
    FlipToBackRounded,
    Money,
    Star,
    TransferWithinAStation,
    VerticalAlignTop,
    Visibility
} from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from 'toastr';
import _ from 'underscore';
import { fetchSellRequest } from '../../store/transaction/transaction.actions';
import { getSellRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';
import Loader from '../base/loader';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function SellContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchSellRequest('Approved'));
    }, []);

    const { sells, isLoading } = useSelector((state) => {
        return {
            sells: getSellRequests(state),
            isLoading: state.TransactionReducer.isLoading
        };
    }, []);

    const actionRenderer = () => {
        return {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(sells, (x) => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        };
    };

    const columns = [
        {
            name: 'createdAt',
            label: 'Transaction Date',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'createdBy',
            label: 'User',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'tokens',
            label: 'Tokens',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'coins',
            label: 'Coins',
            options: {
                filter: false,
                sort: true
            }
        },

        {
            name: 'transactionCharge',
            label: 'Fees',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true
            }
        },
        actionRenderer()
    ];

    const datum = _.sortBy(sells, (t) => moment(t.createdAt))
        .reverse()
        .map((item) => {
            return {
                id: item._id,
                createdBy: item.createdBy,
                createdAt: moment(item.createdAt).format('DD-MM-YYYY'),
                coins:
                    item.transactionCoins +
                    ' ' +
                    (item.transactionMode === 'USDT' ? 'USDT' : item.coinType),
                tokens: item.transactionTokens + ' ' + item.tokenSymbol,
                transactionCharge: item.transactionCharge + ' ' + item.coinType,
                spentHash: item.spentHash,
                receiveHash: item.receiveHash,
                status: item.status
            };
        });

    const options = {
        selectableRows: false
    };

    return (
        <div className="mb-4 pb-4">
            {isLoading && <Loader />}
            <Dialog
                maxWidth="md"
                fullWidth
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle>
                    Sell Transaction{' '}
                    <Close
                        className="mt-1 float-right"
                        onClick={() => setOpen(false)}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <Fingerprint />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.referenceNo}
                                    secondary={'Reference No'}
                                />
                                <ListItemSecondaryAction>
                                    <FileCopy
                                        color="secondary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                selectedItem.referenceNo
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <TransferWithinAStation />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.transactionId}
                                    secondary={'Transaction ID'}
                                />
                                <ListItemSecondaryAction>
                                    <FileCopy
                                        color="secondary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                selectedItem.transactionId
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <Money />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        selectedItem.transactionCoins +
                                        ' ' +
                                        (selectedItem.transactionMode === 'USDT'
                                            ? 'USDT'
                                            : selectedItem.coinType)
                                    }
                                    secondary={'Amount'}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <AccountBalanceWallet />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        selectedItem.transactionTokens +
                                        ' ' +
                                        selectedItem.tokenSymbol
                                    }
                                    secondary={'Amount'}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <AccountBalanceWalletRounded />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        selectedItem.transactionCharge +
                                        ' ' +
                                        selectedItem.coinType
                                    }
                                    secondary={'Transaction Charge'}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <CalendarToday />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={moment(
                                        selectedItem.createdAt
                                    ).format('lll')}
                                    secondary={'Transaction Date'}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <FlipToBackRounded />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        selectedItem.tokenAddress &&
                                        selectedItem.tokenAddress.toString()
                                    }
                                    secondary={'Token Address'}
                                />
                                <ListItemSecondaryAction>
                                    <FileCopy
                                        color="secondary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                selectedItem.tokenAddress
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <FlipToBackRounded />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.createdBy}
                                    secondary={'Created By'}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <Code />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.spentHash}
                                    secondary={'Sender Hash'}
                                />
                                <ListItemSecondaryAction>
                                    <FileCopy
                                        color="secondary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                selectedItem.spentHash
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <Code />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.receiveHash}
                                    secondary={'Receiver Hash'}
                                />
                                <ListItemSecondaryAction>
                                    <FileCopy
                                        color="secondary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                selectedItem.receiveHash
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <Code />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.transactionChargeHash}
                                    secondary={'Charge Hash'}
                                />
                                <ListItemSecondaryAction>
                                    <FileCopy
                                        color="secondary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                selectedItem.transactionChargeHash
                                            );
                                            Toastr.info('copied to clipboard');
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="bg-success">
                                        <Star />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedItem.status}
                                    secondary={'Status'}
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <TitleBar
                heading="Sell Transactions"
                secondary={''}
                icon={
                    <VerticalAlignTop fontSize="large" htmlColor="goldenrod" />
                }
            />
            <div className={classes.root}>
                <MUIDataTable
                    title={'Sell Transactions List'}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div>
    );
}
