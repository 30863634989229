import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { getMemberReferals } from '../../store/member/member.selector';

export default function RefereeContainer() {
    const { referals } = useSelector((state) => {
        return {
            referals: getMemberReferals(state)
        };
    }, []);

    let data = _.sortBy(referals.children, 'depth');
    data = _.groupBy(data, 'depth');

    return (
        <div>
            <div className="content-height m-2 p-2">
                {Object.keys(data).map((x) => {
                    const columns = [
                        'Member #',
                        'Name',
                        'Email',
                        'Mobile Number'
                    ];

                    const datum = data[x].map((y) => {
                        return [
                            y.memberId,
                            y.firstName + ' ' + y.lastName,
                            y.email,
                            y.mobileNumber
                        ];
                    });

                    const options = {
                        selectableRows: false
                    };
                    return (
                        <Card elevation={10} className="mb-2">
                            <CardHeader
                                title={'Level ' + (parseInt(x) + 1)}
                                subheader="Member Information"
                            />
                            <Divider />
                            <CardContent>
                                <MUIDataTable
                                    title={'Member List'}
                                    data={datum}
                                    columns={columns}
                                    options={options}
                                />
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
}
