import { Button, Container, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Save } from '@material-ui/icons';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IsNullOrEmpty, validEmail, validPhone } from '../../helpers/utility';
import { registerMemberRequest } from '../../store/member/member.actions';

export default function SignUp() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        country: '+91',
        mobileNumber: '',
        email: '',
        referedBy: ''
    });

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            const id = query.get('ref');
            if (id) {
                setValues({ ...values, referedBy: id });
            } else {
                setValues({ ...values, referedBy: '' });
            }
        }
    }, [location]);

    const [isValidated, setValidated] = React.useState(false);

    const validate = () => {
        if (IsNullOrEmpty(values.firstName)) {
            return false;
        }
        if (IsNullOrEmpty(values.lastName)) {
            return false;
        }
        if (
            IsNullOrEmpty(values.mobileNumber) ||
            !validPhone(values.mobileNumber)
        ) {
            return false;
        }
        if (IsNullOrEmpty(values.email) || !validEmail(values.email)) {
            return false;
        }
        return true;
    };

    const handleReset = () => {
        setValidated(false);
        setValues({
            firstName: '',
            lastName: '',
            country: '',
            mobileNumber: '',
            email: ''
        });
    };

    const handleSave = () => {
        if (validate()) {
            dispatch(
                registerMemberRequest({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    country: values.mobileNumber.slice(0, 2),
                    mobileNumber: values.mobileNumber.slice(
                        3,
                        values.mobileNumber.length
                    ),
                    email: values.email,
                    referal: values.referedBy || 'ADM00001'
                })
            );
        } else {
            setValidated(true);
        }
    };

    return (
        <Container maxWidth="md">
            <Grid container spacing={4}>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="First Name"
                        label="First Name"
                        value={values.firstName}
                        onChange={(e) =>
                            setValues({ ...values, firstName: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={isValidated && IsNullOrEmpty(values.firstName)}
                        helperText={
                            isValidated && IsNullOrEmpty(values.firstName)
                                ? 'First Name should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Last Name"
                        label="Last Name"
                        value={values.lastName}
                        onChange={(e) =>
                            setValues({ ...values, lastName: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={isValidated && IsNullOrEmpty(values.lastName)}
                        helperText={
                            isValidated && IsNullOrEmpty(values.lastName)
                                ? 'Last Name should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <MuiPhoneNumber
                        variant="outlined"
                        className="px-2"
                        fullWidth
                        value={values.mobileNumber}
                        margin="dense"
                        defaultCountry={'in'}
                        autoFormat={false}
                        onChange={(value) =>
                            setValues({ ...values, mobileNumber: value })
                        }
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.mobileNumber) ||
                                !validPhone(values.mobileNumber))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.mobileNumber) ||
                                !validPhone(values.mobileNumber))
                                ? 'Mobile Number should be valid'
                                : ''
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        variant="outlined"
                        placeholder="Email"
                        label="Email"
                        value={values.email}
                        type="email"
                        onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        error={
                            isValidated &&
                            (IsNullOrEmpty(values.email) ||
                                !validEmail(values.email))
                        }
                        helperText={
                            isValidated &&
                            (IsNullOrEmpty(values.email) ||
                                !validEmail(values.email))
                                ? 'Email should be valid'
                                : ''
                        }
                    />
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        placeholder="Refered By"
                        label="Refered By"
                        value={values.referedBy}
                        onChange={(e) =>
                            setValues({ ...values, referedBy: e.target.value })
                        }
                        fullWidth
                        margin="dense"
                        className="px-2"
                        helperText={
                            "Leave blank, if you don't have any referal now"
                        }
                    />
                </Grid>
                <Grid xs={12} sm={12} className="p-2">
                    <Container maxWidth="sm">
                        <Grid container>
                            <Grid xs={12} sm={12} md={6} lg={6} className="p-2">
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    onClick={() => handleReset()}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6} className="p-2">
                                <Button
                                    startIcon={<Save />}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => handleSave()}
                                >
                                    Add Member
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
