import { action } from 'typesafe-actions';
import { UserActionTypes } from './user.model';

export const createUserRequest = (req) =>
    action(UserActionTypes.REGISTER_USER_REQUEST, req);
export const createUserSuccess = (res) =>
    action(UserActionTypes.REGISTER_USER_SUCCESS, res);
export const createUserFailure = (err) =>
    action(UserActionTypes.REGISTER_USER_FAILURE, err);

export const fetchUserRequest = (req) =>
    action(UserActionTypes.FETCH_USER_REQUEST, req);
export const fetchUserSuccess = (res) =>
    action(UserActionTypes.FETCH_USER_SUCCESS, res);
export const fetchUserFailure = (err) =>
    action(UserActionTypes.FETCH_USER_FAILURE, err);

export const fetchUsersRequest = (req) =>
    action(UserActionTypes.FETCH_USERS_REQUEST, req);
export const fetchUsersSuccess = (res) =>
    action(UserActionTypes.FETCH_USERS_SUCCESS, res);
export const fetchUsersFailure = (err) =>
    action(UserActionTypes.FETCH_USERS_FAILURE, err);

export const updateUserRequest = (req) =>
    action(UserActionTypes.UPDATE_USER_REQUEST, req);
export const updateUserSuccess = (res) =>
    action(UserActionTypes.UPDATE_USER_SUCCESS, res);
export const updateUserFailure = (err) =>
    action(UserActionTypes.UPDATE_USER_FAILURE, err);
