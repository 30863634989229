import {
    Avatar,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    makeStyles
} from '@material-ui/core';
import {
    AccountBalance,
    AccountBox,
    CalendarToday,
    Close,
    Email,
    Fingerprint,
    GradeSharp,
    Phone,
    Save,
    Visibility
} from '@material-ui/icons';
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { IsNullOrEmpty, validEmail, validPhone } from '../../helpers/utility';
import { fetchUsersRequest } from '../../store/user/user.actions';
import UserAPI from '../../store/user/user.api';
import { getUsers } from '../../store/user/user.selector';
import Loader from '../base/loader';
import TitleBar from '../base/TitleBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

export default function UserContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [firstName, setFirstName] = useState('');
    const [country, setCountry] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [isValidated, setValidated] = React.useState(false);

    useEffect(() => {
        dispatch(fetchUsersRequest());
    }, []);

    const { users, isLoading } = useSelector((state) => {
        return {
            users: getUsers(state),
            isLoading: state.UserReducer.isLoading
        };
    }, []);

    useEffect(() => {
        setOpen(false);
        setSelecteItem({});
        setMode('');
    }, [users]);

    const actionRenderer = () => {
        return {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(users, (x) => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        };
    };

    const columns = [
        {
            name: 'userId',
            label: 'User #',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'name',
            label: 'User Name',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'phone',
            label: 'Mobile Number',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'role',
            label: 'Role',
            options: {
                filter: true,
                sort: true
            }
        },
        actionRenderer()
    ];

    const validate = () => {
        if (IsNullOrEmpty(firstName)) {
            return false;
        }
        if (IsNullOrEmpty(lastName)) {
            return false;
        }
        if (IsNullOrEmpty(role)) {
            return false;
        }
        if (IsNullOrEmpty(mobileNumber) || !validPhone(mobileNumber)) {
            return false;
        }
        if (IsNullOrEmpty(email) || !validEmail(email)) {
            return false;
        }
        return true;
    };

    const handleReset = () => {
        setValidated(false);
        setFirstName('');
        setLastName('');
        setCountry('');
        setMobileNumber('');
        setEmail('');
        setRole('');
        setMode('');
    };

    const handleSave = () => {
        if (validate()) {
            UserAPI.RegisterUser({
                firstName: firstName,
                lastName: lastName,
                country: '+91',
                mobileNumber: mobileNumber.slice(3, mobileNumber.length),
                email: email,
                role: role
            }).then((s) => {
                dispatch(fetchUsersRequest());
                handleReset();
            });
        } else {
            setValidated(true);
        }
    };

    const datum = users.map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format('lll'),
            role: item.role,
            userId: item.userId,
            phone: item.mobileNumber,
            email: item.email,
            name: item.firstName + ' ' + item.lastName
        };
    });

    const options = {
        selectableRows: false
    };

    return (
        <div className="mb-4 pb-4">
            {isLoading && <Loader />}
            <Dialog
                maxWidth="xs"
                fullWidth
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle>
                    User Information{' '}
                    <Close
                        className="mt-1 float-right"
                        onClick={() => setOpen(false)}
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Fingerprint />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.userId}
                                secondary={'User ID'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <AccountBox />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    selectedItem.firstName +
                                    ' ' +
                                    selectedItem.lastName
                                }
                                secondary={'User Name'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <GradeSharp />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.role}
                                secondary={'Role'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Phone />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.mobileNumber}
                                secondary={'Mobile Number'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <Email />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectedItem.email}
                                secondary={'Email'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className="bg-primary">
                                    <CalendarToday />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={moment(selectedItem.createdAt).format(
                                    'lll'
                                )}
                                secondary={'Joined Date'}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
            <TitleBar
                heading="Users"
                secondary={''}
                icon={<AccountBalance fontSize="large" htmlColor="goldenrod" />}
                action={
                    <Button
                        color="primary"
                        onClick={() => setMode('add')}
                        variant="contained"
                    >
                        Add User
                    </Button>
                }
            />
            <div className={classes.root}>
                {mode === '' ? (
                    <MUIDataTable
                        title={"User's List"}
                        data={datum}
                        columns={columns}
                        options={options}
                    />
                ) : (
                    <Container maxWidth="sm">
                        <Grid container spacing={4} className="p-3">
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    placeholder="First Name"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    fullWidth
                                    margin="dense"
                                    className="px-2"
                                    error={
                                        isValidated && IsNullOrEmpty(firstName)
                                    }
                                    helperText={
                                        isValidated && IsNullOrEmpty(firstName)
                                            ? 'First Name should be valid'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Last Name"
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    fullWidth
                                    margin="dense"
                                    className="px-2"
                                    error={
                                        isValidated && IsNullOrEmpty(lastName)
                                    }
                                    helperText={
                                        isValidated && IsNullOrEmpty(lastName)
                                            ? 'Last Name should be valid'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <MuiPhoneNumber
                                    variant="outlined"
                                    className="px-2"
                                    fullWidth
                                    value={mobileNumber}
                                    margin="dense"
                                    defaultCountry={'in'}
                                    autoFormat={false}
                                    onChange={(value) => setMobileNumber(value)}
                                    error={
                                        isValidated &&
                                        (IsNullOrEmpty(mobileNumber) ||
                                            !validPhone(mobileNumber))
                                    }
                                    helperText={
                                        isValidated &&
                                        (IsNullOrEmpty(mobileNumber) ||
                                            !validPhone(mobileNumber))
                                            ? 'Mobile Number should be valid'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Email"
                                    label="Email"
                                    value={email}
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                    margin="dense"
                                    className="px-2"
                                    error={
                                        isValidated &&
                                        (IsNullOrEmpty(email) ||
                                            !validEmail(email))
                                    }
                                    helperText={
                                        isValidated &&
                                        (IsNullOrEmpty(email) ||
                                            !validEmail(email))
                                            ? 'Email should be valid'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid
                                md={12}
                                lg={12}
                                sm={12}
                                xs={12}
                                className="p-2"
                            >
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                >
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={role}
                                        onChange={(e) =>
                                            setRole(e.target.value)
                                        }
                                        label="Role"
                                    >
                                        <MenuItem value="Member Admin">
                                            Member Admin
                                        </MenuItem>
                                        <MenuItem value="Transaction Admin">
                                            Transaction Admin
                                        </MenuItem>
                                        <MenuItem value="Report Admin">
                                            Report Admin
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={12} className="p-2">
                                <Grid container>
                                    <Grid
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="p-2"
                                    >
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="secondary"
                                            onClick={() => handleReset()}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="p-2"
                                    >
                                        <Button
                                            startIcon={<Save />}
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            onClick={() => handleSave()}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                )}
            </div>
        </div>
    );
}
